import { useFieldArray, useFormContext } from 'react-hook-form';
import { InputText, SelectControl } from '../../../../components/input';
import React, { Suspense } from 'react';
import { FieldValue } from 'react-hook-form/dist/types/fields';
import { Button, Spinner } from '../../../../components/Elements';
import { HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { InputNumber } from '../../../../components/input/InputNumber';
import { getInputError } from '../../../../lib/getInputError';
const FormulaInput = React.lazy(() => import('../../../customization-process/components/input/FormulaInput'));

type FormulaFormProps = {
  prefix: string;
};

function FormulaForm(props: FormulaFormProps) {
  const { prefix } = props;

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    fields: dependsOnFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.dependsOn`,
  });

  const parameters = watch('parameters');
  const parameter = watch(prefix);
  const formula = watch(`${prefix}.formula`);

  console.log(errors);

  return (
    <div className="w-full">
      <InputText
        className="my-3"
        {...register(`${prefix}.formula`)}
        label="Formula"
        error={getInputError(errors, `${prefix}.formula`)}
      />
      <Suspense fallback={<Spinner size={'h-8 w-8  '} />}>
        {formula && <FormulaInput {...parameter} parameters={parameters} className={'mb-2'} />}
      </Suspense>
      <InputNumber {...register(`${prefix}.accuracy`)} label="Accuracy" min={0} step={1} />
      <div className="my-4 text-gray-600 p-3 border-2 border-gray-300 rounded">
        Depends On
        <div className="grid grid-cols-2 gap-5 mt-3">
          {dependsOnFields?.map((dependsOnField: FieldValue<any>, index: number) => {
            return (
              <div key={dependsOnField.id} className="col-span-1 flex">
                <SelectControl
                  control={control}
                  name={`${prefix}.dependsOn.${index}.parameterName`}
                  label="Parameter"
                  disabled={false}
                  className="flex-1"
                  options={parameters
                    ?.filter((p) => p.type !== 'FLOW_SWITCH' && p.parameterName !== parameter.parameterName)
                    .map((p) => ({
                      value: p.parameterName,
                      label: p.parameterName,
                    }))}
                  error={getInputError(errors, `${prefix}.dependsOn.${index}.parameterName`)}
                />
                <Button type="button" variant={'none'} onClick={() => remove(index)}>
                  <RiDeleteBinLine className="h-6 w-6 text-red-500" />
                </Button>
              </div>
            );
          })}
        </div>
        <Button
          type="button"
          variant="none"
          className="flex w-full mt-4 text-blue-500 border border-blue-500"
          onClick={() => append({ parameterName: '' })}
        >
          <HiPlusCircle className="h-6 w-6 text-blue-500 mr-4" />
          Add dependency
        </Button>
      </div>
    </div>
  );
}

export default FormulaForm;
