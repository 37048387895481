import { useFormContext } from 'react-hook-form';
import { InputText, SelectControl } from '../../../../components/input';
import React, { useMemo, useState } from 'react';
import { VariableCustomization } from './index';
import { HiArrowsPointingOut } from 'react-icons/hi2';
import FlowSwitchForm from './FlowSwitchForm';
import classNames from '../../../../utils/classNames';
import DisplayManagement from '../BasicScenarioTemplate/DisplayManagement';
import { DisplayConfiguration, Parameter } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import SlidePanel from '../../../../components/SlidePanel/SlidePanel';
import { getInputError, getInputErrors } from '../../../../lib/getInputError';

type VariableFormProps = {
  prefix: string;
  includeDisplay?: boolean;
  displayConfigurations: DisplayConfiguration[];
  expanded?: boolean;
  className?: string;
  color?: string;
} & Parameter;

function BasicParameterForm(props: VariableFormProps) {
  const { prefix, parameterName, display, expanded, includeDisplay, color, displayConfigurations, className } = props;

  const [open, setOpen] = useState(false);

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const parameterType = watch(`${prefix}.parameterType`);
  const type = watch(`${prefix}.type`);

  const Customization = useMemo(() => VariableCustomization[parameterType], [parameterType, type]);

  const render = (
    <div className={'bg-white px-4 h-full'}>
      <InputText
        {...register(`${prefix}.alias`)}
        label={'Alias'}
        className="my-3"
        error={getInputError(errors, `${prefix}.alias`)}
      />
      <InputText {...register(`${prefix}.value`)} label={'Default Value'} className="my-3" />
      <InputText {...register(`${prefix}.description`)} label={'Description'} className="my-3" />
      <InputText {...register(`${prefix}.unitOfMeasure`)} label={'Unit'} className="my-3" />
      <InputText {...register(`${prefix}.tooltip`)} label={'Tooltip description'} className="my-3" />
      <InputText {...register(`${prefix}.editable`)} label={'Editable'} className="my-3" />
      <InputText {...register(`${prefix}.hidden`)} label={'Hidden'} className="my-3" />
      <SelectControl
        control={control}
        name={`${prefix}.type`}
        className="my-3"
        label={'Type'}
        options={['AMOUNT_CHANGE', 'FLOW_SWITCH', 'ADDITIONAL_PARAMETER'].map((o) => ({ label: o, value: o }))}
      />

      <SelectControl
        control={control}
        name={`${prefix}.parameterType`}
        className="my-3"
        disabled={type === 'FLOW_SWITCH'}
        label={'Parameter type'}
        options={['FORMULA', 'OPTION', 'NUMERIC', 'STRING', 'REMOTE', 'LIST'].map((o) => ({ label: o, value: o }))}
      />
      {type === 'FLOW_SWITCH' && <FlowSwitchForm prefix={prefix} />}
      {Customization ? <Customization {...props} requiresDefault={type === 'FLOW_SWITCH'} /> : null}

      <div className="p-4 border border-gray-300 mt-3 rounded">
        <DisplayManagement
          display={display}
          parameterPrefix={`${prefix}.display`}
          displayConfigurations={displayConfigurations}
        />
      </div>
    </div>
  );
  return (
    <div className={classNames(className ? className : '', 'w-full')}>
      <div className={'flex bg-transparent'}>
        <InputText
          label={'Parameter name'}
          {...register(`${prefix}.parameterName`)}
          className={classNames(color ? `${color} border-2 rounded` : '', 'flex-1 hover:cursor-pointer')}
          error={
            getInputError(errors, `${prefix}.parameterName`) ??
            (Object.keys(getInputErrors(errors, prefix) ?? {}).length > 0 ? 'Error present inside parameter' : '')
          }
          addonBefore={<HiArrowsPointingOut className="w-6 h-6 mt-2 " onClick={() => setOpen(!open)} />}
          addonAfter={(() => {
            switch (parameterType) {
              case 'OPTION':
                return <i>opt</i>;
              case 'NUMERIC':
                return <i>123</i>;
              case 'FORMULA':
                return <i>a * b</i>;
              case 'STRING':
                return <i>aBc</i>;
              case 'REMOTE':
                return <i>remote</i>;
            }
          })()}
        />
      </div>
      {expanded ? (
        <>{render}</>
      ) : (
        <SlidePanel open={open} size={'xl'} setOpen={setOpen} label={parameterName}>
          {render}
        </SlidePanel>
      )}
    </div>
  );
}

export default BasicParameterForm;
