import classNames from '../../utils/classNames';
import CustomSelect from 'react-select';
import React, { Fragment, useMemo, useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

type SelectProps = {
  options: { label: string | undefined; value: any; disabled?: boolean; color?: string }[] | undefined;
  label?: string;
  className?: string;
  style?: { [key: string]: string };
  id?: string;
  name?: string;
  value?: any;
  confirm?: boolean;
  onChange: (value: any) => void;
  accessorFn?: (value: any) => string;
  description?: string;
  disabled?: boolean;
  error?: string | null | undefined;
  isMulti?: boolean;
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

export const SimpleSelect = React.forwardRef<any, SelectProps>((props, ref) => {
  const {
    confirm,
    options,
    accessorFn,
    label,
    id,
    name,
    className,
    error,
    value,
    size,
    onChange,
    placeholder,
    disabled,
    description,
    isMulti,
  } = props;

  const selectStyle = useMemo(
    () => ({
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: 'none',
        paddingTop: size !== 'sm' ? '10px' : '0px',
        backgroundColor: '#F8FAFC',
        boxShadow: 'none',
        minHeight: '20px',
        ...(size === 'sm' ? { maxHeight: '30px' } : {}),
      }),
      placeholder: (baseStyles, state) => ({
        ...baseStyles,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...(error ? { color: '#dc2626', opacity: 0.7 } : {}),
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        color: state.data.color ? state.data.color : 'black',
        zIndex: 1000,
      }),
      container: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: '20px',
        flex: 1,
        ...(size === 'sm' ? { fontSize: '12px' } : {}),
      }),
      menu: (baseStyles, state) => ({
        ...baseStyles,
        zIndex: 1000,
        marginTop: '2px',
      }),
      menuList: (baseStyles, state) => ({
        ...baseStyles,
        zIndex: 1000,
      }),
    }),
    [error, size],
  );

  return (
    <div className={classNames(className ? className : '', 'relative ')}>
      <label
        style={{
          fontSize: size === 'sm' ? '10px' : '12px',
        }}
        className="text-xs ml-2 whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-65%  via-transparent via-65% to-transparent absolute -top-[8px] z-[2] left-4 px-1 text-gray-700 rounded"
      >
        {label}
      </label>
      <div
        className={`flex border rounded border-gray-300 ${error ? 'border-red-500' : ''}`}
        style={{ backgroundColor: '#F8FAFC' }}
      >
        {isMulti ? (
          <CustomSelect
            isMulti
            key={id}
            id={id}
            noOptionsMessage={(e) => 'No options available'}
            name={name}
            placeholder={
              error
                ? error
                : !options || options.length === 0
                  ? 'No options available'
                  : placeholder
                    ? placeholder
                    : 'Select an option'
            }
            isDisabled={disabled ? disabled : !options || options.length === 0}
            classNames={{}}
            styles={selectStyle}
            isOptionSelected={(option, selectValues) => {
              return selectValues.some((sv) =>
                accessorFn ? accessorFn(sv?.value) === option?.value : sv?.value === option?.value,
              );
            }}
            options={accessorFn ? options?.map((o) => ({ label: o.label, value: accessorFn(o.value) })) : options}
            value={(() => {
              let a = null;
              if (options && value) {
                if (accessorFn) {
                  a = value.map((v) => ({
                    label: options.find((o) => accessorFn(v) === accessorFn(o.value))?.label,
                    value: v,
                  }));
                } else {
                  a = value.map((v) => ({
                    label: options.find((o) => v === o.value)?.label,
                    value: v,
                  }));
                }
              }
              // console.log("value:::::", a)
              return a;
            })()}
            ref={ref}
            onChange={(values) => {
              const a = values?.map((selectedValue) => {
                if (accessorFn && typeof selectedValue?.value === 'string') {
                  return options?.find((o) => accessorFn(o.value) === selectedValue.value)?.value ?? null;
                } else {
                  return selectedValue?.value;
                }
              });
              onChange(a);
            }}
            className="flex-1 bg-white "
          />
        ) : (
          <CustomSelect
            key={id}
            id={id}
            name={name}
            placeholder={
              error
                ? error
                : !options || options.length === 0
                  ? 'No options available'
                  : placeholder
                    ? placeholder
                    : 'Select an option'
            }
            isDisabled={disabled ? disabled : !options || options.length === 0}
            styles={selectStyle}
            options={options}
            value={options ? options.find((o) => o.value === value) ?? null : null}
            ref={ref}
            isOptionDisabled={(option) => option.disabled as boolean}
            onChange={(e) => {
              onChange(e?.value);
            }}
            className="flex-1 bg-white relative"
          />
        )}
        {error && (
          <Fragment>
            <div className={'bg-gray-50 rounded mr-2'}>
              <HiOutlineExclamationCircle
                id={`${name?.replaceAll('.', '')}-error`}
                className="focus:outline-none w-5 text-red-500 flex-shrink-0 self-center h-full focus-visible:outline-none shadow-none cursor-default"
              />
            </div>
            <Tooltip
              anchorSelect={`#${name?.replaceAll('.', '')}-error`}
              place={'top-end'}
              className="cursor-none"
              variant={'error'}
              style={{ backgroundColor: 'white', color: 'rgb(239, 68, 68)' }}
              border="1px solid rgb(239, 68, 68)"
            >
              {error}
            </Tooltip>
          </Fragment>
        )}
        {/*{confirm ? (*/}
        {/*  value != value ? (*/}
        {/*    <Button variant={'none'} className={'text-blue-600'} onClick={() => onChange(value)}>*/}
        {/*      Apply*/}
        {/*    </Button>*/}
        {/*  ) : (*/}
        {/*    <HiCheckCircle className={'text-green-700 h-8 w-8 mt-2 mr-2'} />*/}
        {/*  )*/}
        {/*) : undefined}*/}
      </div>

      {/*{error && <p className="text-sm text-red-500">{error}</p>}*/}

      {description && <p className="ml-2 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
