import { Controller, useFormContext } from 'react-hook-form';
import { ParameterType } from '../../../../api/customizationSpace/types/ParameterType';
import React, { useEffect, useMemo } from 'react';
import { useCustomizationTemplateContext } from '../../hooks/CustomizationTemplateProvider';
import { getInputError } from '../../../../lib/getInputError';
import { IndexedParameter } from '../CustomizationTemplateForm';
import { Option, Parameter } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { InputSubCategories } from './index';
import { evaluateScriptWithScope } from '../../utils/evaluateScriptWithScope';

type ParameterInputProps = IndexedParameter & {
  width: number | undefined;
  onFormChange: () => void;
  parameters: IndexedParameter[];
  className?: string;
};

export const ParameterInput = (props: ParameterInputProps) => {
  const {
    parameters,
    value,
    parameterName,
    description,
    parameterType,
    editable,
    hidden,
    validations,
    index,
    className,
    onFormChange,
  } = props;

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext();

  const { displayErrors } = useCustomizationTemplateContext();

  const CustomInput = InputSubCategories[parameterType];

  const rules = useMemo(() => {
    if (!hidden) {
      const schema: any = {
        // required: "This field is required.",
        required: `This field is required.`,
        validate: {},
      };

      if (ParameterType[parameterType] === ParameterType.NUMERIC) {
        schema.validate['validateNumber'] = (e) =>
          !isNaN(Number(e)) ? true : 'This field should be a number' + parameterName;
      }

      validations?.forEach((v) => {
        switch (v.type) {
          case 'max':
            schema.validate['max'] = (e: number) => (e > (v.value as number) ? v.message : true);
            break;
          case 'min':
            schema.validate['min'] = (e: number) => (e < (v.value as number) ? v.message : true);
            break;
        }
      });
      return schema;
    }
    return {};
  }, [hidden]);

  useEffect(() => {
    trigger();
  }, [rules]);

  return (
    <Controller
      name={`parameters.${index}.value`}
      control={control}
      rules={rules}
      defaultValue={value}
      render={({ field: { ref, ...field } }) => {
        // This logic allows for updating
        const defaultOnChange = field.onChange;
        field.onChange = (value: string) => {
          if (ParameterType[parameterType] === ParameterType.OPTION) {
            const option = (props as Parameter).options?.find((x: Option) => x.value == value);
            if (!!option?.linkedParameters) {
              option.linkedParameters.forEach((lp) => {
                const linkedParameter = parameters.find((p) => p.parameterName === lp.parameterName);
                if (linkedParameter) {
                  setValue(`parameters.${linkedParameter?.index}.value`, lp.value);
                } else {
                  console.warn('Linked parameter: ', lp.parameterName, ' not found. Triggered by: ', parameterName);
                }
              });
            }
          }
          defaultOnChange(value);
          onFormChange();
          trigger();
        };

        return (
          <CustomInput
            className={className}
            key={parameterName}
            error={displayErrors ? getInputError(errors, field.name) : undefined}
            {...props}
            description={description}
            {...field}
            {...(props.options
              ? {
                  options: props.options.filter((opt: Option) =>
                    evaluateScriptWithScope(opt.condition, parameters, parameterName),
                  ),
                }
              : undefined)}
            value={field.value ?? ''}
            disabled={!evaluateScriptWithScope(editable, parameters, parameterName)}
            type={ParameterType[parameterType] === ParameterType.NUMERIC ? 'number' : 'string'}
            ref={ref}
            hidden={false}
          />
        );
      }}
    />
  );
};
