import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useStateMachine } from 'little-state-machine';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetAllImpactMethodsQuery,
  useGetAllTreesInfoQuery,
} from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { useGetAllPhaseQuery } from '../../../../api/project/projectApi';
import { useGetScenarioTemplateQuery } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { useBreadcrumbs } from '../../../../hooks/breadcrumbs';
import ScenarioTemplateForm from './ScenarioTemplateForm';
import { Spinner } from '../../../../components/Elements';
import { NavigationStep } from '../../../../components/navigation/GuidedNavigation';

export const scenarioTemplateSteps: NavigationStep[] = [
  {
    id: '01',
    name: 'Parameters customization',
    href: '/app/templates/basic/customization',
  },
  {
    id: '02',
    name: 'Tab and Group creation',
    href: '/app/templates/basic/tabs',
  },
  {
    id: '03',
    name: 'Parameter Display',
    href: '/app/templates/basic/parameters',
  },
  {
    id: '04',
    name: 'Check Layout',
    href: '/app/templates/basic/layout',
  },
];

function ScenarioTemplate() {
  const { templateId } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { state } = useStateMachine();
  const { data: template, isLoading } = useGetScenarioTemplateQuery(
    templateId && state.template?.id !== templateId ? { scenarioTemplateId: templateId } : skipToken,
  );

  const { data: phases } = useGetAllPhaseQuery({});
  const { data: trees } = useGetAllTreesInfoQuery({
    includeSubprocesses: true,
  });
  const { data: impactMethods } = useGetAllImpactMethodsQuery({});

  const templateForm = useMemo(() => {
    if (template || !state.template) {
      return template;
    } else if (state.template.id !== templateId) {
      console.log('here', template);
      return template;
    }
    return state.template;
  }, [template]);

  useEffect(() => {
    if (template) {
      setBreadcrumbs([
        {
          label: 'templates',
          path: 'app/templates',
        },
        ...(template && [{ label: `${template.name}` }]),
      ]);
    }
  }, [template]);

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <>
      {(templateForm || !templateId) && phases && trees && impactMethods && (
        <ScenarioTemplateForm
          initialValues={templateForm}
          phases={phases}
          trees={trees}
          impactMethods={impactMethods}
        />
      )}
    </>
  );
}

export default ScenarioTemplate;
