import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicParameterForm from '../Parameter/BasicParameterForm';
import DisplayManagement from './DisplayManagement';
import { toast } from 'react-hot-toast/headless';
import {
  Commit,
  usePostCommitMutation,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { HiArrowDownOnSquare, HiDocumentDuplicate, HiOutlineEye } from 'react-icons/hi2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { scenarioTemplateSteps } from './ScenarioTemplate';
import { basicScenarioTemplateSchema } from '../validation/basicScenarioTemplateSchema';
import { getInputErrors } from '../../../../lib/getInputError';
import classNames from '../../../../utils/classNames';
import GuidedNavigation from '../../../../components/navigation/GuidedNavigation';
import ButSaveModal from '../ButSaveModal';
import ButSlidePanel from '../../../../components/SlidePanel/ButSlidePanel';
import { FiGitCommit } from 'react-icons/fi';
import CommitList from '../CommitList';
import { Tooltip } from 'react-tooltip';
import ButModal from '../../../../components/Modal/ButModal';
import { BsFiletypeCsv } from 'react-icons/bs';
import append from 'react-hook-form/dist/utils/append';

function ScenarioTemplateParameters() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const [postCommit] = usePostCommitMutation();

  const methods = useForm({
    defaultValues: { ...state.template },
    resolver: (values, context, options) => yupResolver(basicScenarioTemplateSchema)(values, values, options),
    mode: 'onChange',
  });
  const {
    setValue,
    control,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    } else {
      trigger();
    }
  }, []);

  const { fields: parameterFields, append } = useFieldArray({
    control,
    name: 'parameters',
  });

  const displayConfigurations: any[] = useWatch({ name: 'displayConfigurations', control });

  const handleNavigate = (href: string) => {
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(href);
  };

  const handleFormSubmit = async (values: any, valid: boolean, commit: Commit) => {
    console.log('Template', { values });
    values.valid = valid;
    const scenarioTemplate: any = await postScenarioTemplate({ body: values });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      postCommit({
        commit: {
          ...commit,
          message: `${commit?.message}${!valid ? ' [saved with error]' : ''}`,
          scenarioTemplateId: scenarioTemplate.data.id,
        } as Commit,
      });
      if (!values.id) {
        setValue('id', scenarioTemplate.id);
      }
      toast.success('Scenario template created successfully');
    }
  };

  const handleTrigger = async () => {
    const result = await trigger();
    if (result) {
      toast.success('Scenario template is valid');
    } else {
      toast.error('Scenario template contains some errors');
    }
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleNavigate(href)}
        className="mt-2"
      />
      <form className="bg-white mt-2 flex flex-col shadow rounded">
        <div className="mt-2 flex ml-auto">
          <Tooltip variant={'info'} content="Save" anchorSelect={'#save'} className="float-right mr-2" />
          <ButSaveModal
            buttonLabel={<HiArrowDownOnSquare id={'save'} className="focus:outline-none w-8 h-8 cursor-pointer mr-2" />}
            onFormSubmit={handleFormSubmit}
            scenarioTemplateId={state.template?.id}
          />
          <Tooltip variant={'info'} content="Copy" anchorSelect={'#copy'} className="float-right mr-2 " />
          <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
            <HiDocumentDuplicate id={'copy'} className="focus:outline-none w-8 h-8 cursor-pointer mr-2" />
          </CopyToClipboard>
          <Tooltip variant={'info'} anchorSelect={'#info'} content="Commits" />
          {state.template?.id && (
            <ButSlidePanel
              id={'info'}
              buttonSize={'none'}
              buttonLabel={<FiGitCommit className={'h-8 w-8 cursor-pointer mr-2'} />}
              label={'Changes on the scenario template'}
            >
              <CommitList scenarioTemplateId={state.template?.id} />
            </ButSlidePanel>
          )}
          <Tooltip variant={'info'} anchorSelect={'#validate'} content="Validate" />
          <HiOutlineEye
            className="h-8 w-8 cursor-pointer focus:outline-none mr-2"
            id={'validate'}
            onClick={() => handleTrigger()}
          />
        </div>
        <div className="flex-1 grid grid-cols-2 gap-3 my-2 p-3">
          {parameterFields.map((parameterField: any, index: number) => {
            const parameterErrors = getInputErrors(errors, `parameters.${index}`);
            return (
              <div
                key={parameterField.id}
                className={classNames(
                  parameterErrors ? 'border-red-300' : 'border-green-300',
                  'col-span-1 rounded bg-white p-3 border-2',
                )}
              >
                <BasicParameterForm
                  {...parameterField}
                  displayConfigurations={displayConfigurations}
                  errors={parameterErrors}
                  defaultValues={{}}
                  prefix={`parameters.${index}`}
                />
                <DisplayManagement
                  display={parameterField.display}
                  displayConfigurations={displayConfigurations}
                  parameterPrefix={`parameters.${index}.display`}
                />
              </div>
            );
          })}
        </div>
      </form>
    </FormProvider>
  );
}

export default ScenarioTemplateParameters;
