import { ParameterType } from '../../../../api/customizationSpace/types/ParameterType';
import { RemoteInput } from './RemoteInput';
import { ParameterInput } from './ParameterInput';
import { SimpleSelect } from '../../../../components/input/SimpleSelect';
import { InputText } from '../../../../components/input';
import { InputNumber } from '../../../../components/input/InputNumber';
import React from 'react';

const FormulaInput = React.lazy(() => import('./FormulaInput'));

export const InputSubCategories: { [key in string]: any } = {
  [ParameterType[ParameterType.NUMERIC]]: InputNumber,
  [ParameterType[ParameterType.STRING]]: InputText,
  [ParameterType[ParameterType.OPTION]]: SimpleSelect,
};

export const Inputs = {
  [ParameterType[ParameterType.REMOTE]]: RemoteInput,
  [ParameterType[ParameterType.FORMULA]]: FormulaInput,
  // [ParameterType[ParameterType.LIST]]: InputList,
  [ParameterType[ParameterType.NUMERIC]]: ParameterInput,
  [ParameterType[ParameterType.OPTION]]: ParameterInput,
  [ParameterType[ParameterType.STRING]]: ParameterInput,
};
