import { useFormContext } from 'react-hook-form';
import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import classNames from '../../../utils/classNames';
import React, { Fragment, Suspense, useMemo } from 'react';
import useRenderCounter from './../../../hooks/useRenderCounter';
import { useResizeDetector } from 'react-resize-detector';
import { IndexedParameter } from './CustomizationTemplateForm';
import { InputList } from '../../../components/input/InputList';
import { ItemProperty, ListParameter } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { InputText } from '../../../components/input';
import { Button, Spinner } from '../../../components/Elements';
import { evaluateScriptWithScope } from '../utils/evaluateScriptWithScope';
import { Inputs } from './input';
/*import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';*/
import { Tooltip } from 'react-tooltip';

type CustomizationParameterFormProps = IndexedParameter & {
  onFormChange: () => void;
};

export const CustomizationParameterForm = (props: CustomizationParameterFormProps) => {
  const { alias, parameterName, tooltip, parameterType, unitOfMeasure, hidden } = props;

  const { register, control, watch, formState, getValues } = useFormContext();

  const renderCounter = useRenderCounter();
  const { width, ref } = useResizeDetector();
  const parameters: IndexedParameter[] = watch('parameters');

  const hide = useMemo(
    () => (hidden ? evaluateScriptWithScope(hidden, getValues().parameters, parameterName) : false),
    [hidden, formState],
  );

  const Input = Inputs[parameterType];

  return (
    <>
      <div ref={ref} className={classNames(hide ? 'hidden' : 'block', 'bg-white px-4 p-1 xs:p-4')}>
        <div className="grid grid-cols-3 gap-6">
          {width && width > 768 ? (
            <div className="col-span-1 flex items-center">
              <h3 className="flex text-base font-semibold leading-6 text-gray-900">
                {alias} {unitOfMeasure ? `[${unitOfMeasure}]` : ''}
                {tooltip && (
                  <Fragment>
                    <Button
                      size={'sm'}
                      variant="none"
                      id={`${props.parameterName}-tooltip`}
                      className={classNames(
                        'bg-blue-100 rounded text-sm z-2 ml-4 pt-1 h-6 w-6 cursor-pointer hover:bg-blue-400 hover:text-white',
                      )}
                    >
                      i
                    </Button>

                    {/*<GrStatusInfo id={`${props.parameterName}-tooltip`} className="h-6 w-6 ml-2 outline-0" />*/}

                    <Tooltip
                      anchorSelect={`#${props.parameterName}-tooltip`}
                      content={tooltip as string}
                      style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
                      border="1px solid rgb(29, 78, 213)"
                    />
                  </Fragment>
                )}
              </h3>
            </div>
          ) : undefined}
          <div className={classNames(width && width > 768 ? 'col-span-2' : 'col-span-3', 'mt-2')}>
            {Input && (
              <Suspense fallback={<Spinner size={'h-8 w-8  '} />}>
                <Input
                  {...props}
                  label={width && width < 768 ? `${alias}${unitOfMeasure ? ' [' + unitOfMeasure + ']' : ''}` : ''}
                  parameters={parameters}
                  hidden={hide}
                />
              </Suspense>
            )}

            {[ParameterType[ParameterType.LIST]].includes(parameterType) && (
              <InputList
                control={control}
                name={`parameters.${props.index}.value`}
                {...props}
                item={(props as ListParameter).listItem?.reduce((acc, current: ItemProperty) => ({
                  ...acc,
                  [current.name as string]: '',
                }))}
                hidden={hide}
                label={width && width < 768 ? `${alias} ${unitOfMeasure ? `[${unitOfMeasure}]` : ''}` : ''}
                renderItem={(field, index, remove, append) => {
                  return (
                    <div className={'col-span-12 mt-3 border border-blue-300 p-3 rounded text-center'}>
                      <p className="self-center font-bold">Item {index + 1}</p>
                      {(props as ListParameter).listItem?.map((property: ItemProperty) => {
                        return (
                          <InputText
                            className="my-3"
                            {...register(`parameters.${props.index}.value.${index}.${property.name}`)}
                            label={property.name}
                          />
                        );
                      })}
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
