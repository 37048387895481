import { HiOutlineTrash } from 'react-icons/hi2';
import { useFormContext } from 'react-hook-form';
import { InputText } from '../../../../components/input';
import React from 'react';
import { InputList } from '../../../../components/input/InputList';

type ListFormProps = {
  prefix: string;
  requiresDefault: boolean;
};

function ListForm(props: ListFormProps) {
  const { prefix, requiresDefault } = props;

  const { control, register } = useFormContext();

  return (
    <div className="w-full">
      <InputList
        label={'Item format'}
        control={control}
        name={`${prefix}.listItem`}
        item={{ name: '' }}
        renderItem={(field, index, remove, append) => {
          return (
            <div className="col-span-12 mx-1 my-2 flex">
              <InputText
                label={`Item property ${index}`}
                {...register(`${prefix}.listItem.${index}.name`)}
                className="flex-1"
              />
              <HiOutlineTrash
                className="h-8 w-8 text-red-500 self-center mx-2 cursor-pointer"
                onClick={() => remove(index)}
              />
            </div>
          );
        }}
      />
    </div>
  );
}

export default ListForm;
