import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Attribute, Bom, Item } from '../../../api/bom/bomApi';
import BomTreeNode from './BomTreeNode';
import '../style/style.css';
import classNames from '../../../utils/classNames';
import { useResizeDetector } from 'react-resize-detector';
import { Transition } from '@headlessui/react';
import { Button } from '../../../components/Elements';
import { PiTreeViewLight } from 'react-icons/pi';

type BillOfMaterialProps = {
  bom: Bom;
  className?: string;
  itemContent?: (
    item: Item & {
      parentId?: string;
    },
    selectedItemId: string | undefined,
    items: Item[],
  ) => React.ReactElement | string | undefined;
  nodeAppendix?: (
    item: Item & {
      parentId?: string;
    },
    width: number,
    items: Item[],
  ) => React.ReactElement | string | null;
  nodeConfiguration: {
    nodeContent?: (item: Item & { parentId?: string }, items: Item[]) => React.ReactNode;
    backgroundColor?: ((item: Item & { parentId?: string }, items: Item[]) => string) | string;
    borderColor?: ((item: Item & { parentId?: string }, items: Item[]) => string) | string;
  };
  contributionTree?: boolean;
  fromComparison?: boolean;
};

function BillOfMaterial(props: BillOfMaterialProps) {
  const { bom, nodeConfiguration, className, nodeAppendix, itemContent, contributionTree, fromComparison } = props;

  const [item, setItem] = useState<any>();
  const [compressedView, setCompressedView] = useState(false);
  const [render, setRender] = useState<boolean>(false);
  const [treeExpanded, setTreeExpanded] = useState<boolean>(true);
  const [info, setInfo] = useState<boolean>(false);

  const topLevelItem = useMemo(() => {
    const item = bom?.items?.find((item) => item.root);
    setItem({ ...item, parentId: 'root' });
    return item;
  }, [bom]);
  const itemContentRef = React.useRef<any>(null);
  // const ref = React.useRef<any>(null);

  const { width, ref } = useResizeDetector();
  useEffect(() => {
    if (width) {
      setRender(true);
      if (!nodeAppendix) setCompressedView(width ? width < 1100 : false);
    }
  }, [width]);

  const handleSelectItem = (item: any) => {
    if (!info) {
      setTreeExpanded(!item);
    }
    setItem(item);
  };

  return (
    <div className={classNames(className ? className : '', 'flex flex-row flex-grow bg-white relative')} ref={ref}>
      {!nodeAppendix && treeExpanded && compressedView ? (
        <div
          className="bg-opacity-50 absolute bg-gray-300 z-[11] w-full h-full right-0 cursor-pointer"
          onClick={() => setTreeExpanded(false)}
        ></div>
      ) : null}
      {render && topLevelItem && (
        <Fragment>
          <Transition
            show={!treeExpanded && compressedView}
            enter="transition-opacity duration-250"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-250"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="outside-circle cursor-pointer rounded-t content-center"
              onClick={() => setTreeExpanded(true)}
            >
              <PiTreeViewLight className="h-6 w-6 mt-1 ml-2 z-50 rotate-90" />
            </div>
          </Transition>

          <div
            className={classNames(
              !treeExpanded && compressedView ? 'treeview-closed' : '',
              compressedView ? 'treeview-compressed z-[13] h-full' : '',
              contributionTree ? 'bom-contribution-tree w-full' : 'w-[35%]',
              'treeview flex flex-col bg-white relative border-t-[1px] shadow ',
            )}
          >
            <div className="treeview overflow-y-scroll flex flex-col flex-shrink bg-white relative h-full">
              <ul>
                <BomTreeNode
                  className="p-2"
                  parentId={'root'}
                  displayAppendix={true}
                  node={topLevelItem}
                  items={bom?.items}
                  quantity={1}
                  nodeAppendix={nodeAppendix}
                  nodeConfiguration={nodeConfiguration}
                  selectedItem={item}
                  itemContentRef={itemContentRef}
                  onSelectItem={handleSelectItem}
                  itemContent={itemContent}
                  containerWidth={width as number}
                />
              </ul>
            </div>
            <Button
              variant="none"
              onClick={() => setInfo(!info)}
              className={classNames(
                !compressedView ? '' : treeExpanded ? '' : 'hidden',
                contributionTree ? 'hidden' : '',
                'bg-blue-100 rounded z-2 mt-3 mr-3 pt-1 h-8 w-6 cursor-pointer flex-shrink absolute right-0 hover:bg-blue-400 hover:text-white',
              )}
            >
              i
            </Button>

            {!nodeAppendix && info && (!compressedView || (treeExpanded && compressedView)) ? (
              <div className={classNames('relative flex flex-col overflow-scroll border-white bg-gray-200 z-10 px-3')}>
                <table className="truncate" style={fromComparison ? { fontSize: '13px' } : { fontSize: '16px' }}>
                  <thead className={'bg-gray-200'} style={{ position: 'sticky', top: '0' }}>
                    <th className="p-1">Field</th>
                    <th className="p-1">Description</th>
                  </thead>
                  <tbody>
                    {item?.attributes.map((a: Attribute) => (
                      <tr className={classNames(item.attributes.indexOf(a) % 2 == 0 ? 'bg-white' : 'bg-gray-200')}>
                        <td className="whitespace-wrap pl-1 pr-1">{a.name}</td>
                        <td className="px-1">{a.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </Fragment>
      )}

      {/* Below item content display using a portal*/}
      {itemContent ? (
        <div
          className={classNames(
            compressedView && !treeExpanded ? 'rounded' : 'border-l',
            fromComparison ? '' : 'shadow',
            'flex-1 flex flex-col overflow-x-scroll z-10',
          )}
          style={{ flex: `${nodeAppendix ? '0' : '7'} 0`, width: 'inherit' }}
          ref={itemContentRef}
        ></div>
      ) : null}
    </div>
  );
}

export default BillOfMaterial;
